import React, { useMemo } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import logo from './logo.svg';
import './App.css';
import RequestForm from "../src/component/Form.js"
import KnowledgeBase from "./component/KnowledgeBase";
import KnowledgeBaseArticle from "./component/KnowledgeBaseArticle";
import Resolution from "./component/Resolution.js";


const App = () => {


  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<RequestForm/>} />
          <Route path="/knowledge-base" element={<KnowledgeBase />} />
          <Route path="/knowledge-base/:kb_id" element={<KnowledgeBaseArticle />} />
          <Route path="/resolution/:ticket_id" element={<Resolution />} /> 
        </Routes>
    </BrowserRouter>
  );
};

export default App;

